/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'idlewild';
    src: url('assets/fonts/Idlewild-Book.otf') format('opentype') ;
    font-weight: lighter;
  }

  
@font-face {
    font-family: 'idlewild';
    src: url('assets/fonts/Idlewild-Medium.otf') format('opentype') ;
    font-weight: normal;
  }

@font-face {
    font-family: 'idlewild';
    src: url('assets/fonts/Idlewild-Bold.otf') format('opentype') ;
    font-weight: bold;
}

*:not(i) {
    font-family: 'idlewild' !important;
    /* font-size: 100% !important; */
}